import React from 'react';
import { ArrowUpRight } from 'lucide-react';

const HeroButtons = ({
  onStartInterviewing,
  onBookDemo,
}: {
  onStartInterviewing: () => void;
  onBookDemo: () => void;
}) => {
  return (
    <div className="flex flex-col sm:flex-row gap-2 md:gap-4">
      <button
        onClick={onStartInterviewing}
        className="bg-black text-white px-8 py-4 md:py-5 rounded-2xl font-medium text-lg sm:text-2xl flex items-center justify-center hover:bg-gray-800 transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
      >
        Start Interviewing
        <ArrowUpRight className="ml-2 mt-1 h-5 w-5 animate-pulse" />
      </button>
      <div className="md:-my-[1.5px] w-full sm:w-auto">
        <button
          onClick={onBookDemo}
          className="w-full sm:w-auto bg-white text-black px-8 py-4 md:py-5 rounded-2xl font-medium text-lg sm:text-2xl flex items-center justify-center border border-gray-300 hover:bg-gray-100 transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
        >
          Book Demo
        </button>
      </div>
    </div>
  );
};

interface HeroSectionProps {
  headerLineHeightStyle: React.CSSProperties;
  linearText: string;
  navigateAuth: () => void;
  primaryButtonText: string;
  openBookingLinkInNewTab: () => void;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  headerLineHeightStyle,
  linearText,
  navigateAuth,
  primaryButtonText,
  openBookingLinkInNewTab,
}) => {
  return (
    <div className="sm:w-11/12 lg:w-full 2xl:6/12 sm:m-auto">
      <div className="flex flex-col justify-center items-center text-primary-contrast text-center bg-opacity-60 md:px-0 pt-14 pb-20 lg:pt-12 xl:pt-16 2xl:pt-24 sm:pb-36 transform">
        <h1
          style={{ ...headerLineHeightStyle, lineHeight: '1.1' }}
          className="text-3.2xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-7.5xl w-full lg:w-11/12 xl:w-9/12 2xl:w-8/12 text-center font-bold relative tracking-tighter word-spacing-tighter"
        >
          <span className="block mb-2 word-spacing-tighter">
            The fastest way to get
          </span>
          <span className="relative inline-block">
            user
            <span className="absolute -bottom-1 left-0 w-full h-1 md:h-1.5 bg-gradient-to-r from-secondary-accent to-transparent"></span>
          </span>{' '}
          <span className="relative inline-block">
            feedback
            <span className="absolute -bottom-1 left-0 w-full h-1 md:h-1.5 bg-gradient-to-r from-transparent via-secondary-accent to-transparent"></span>
          </span>
        </h1>
        <p
          style={{ lineHeight: '1.45' }}
          className="mb-6 md:mb-12 text-lg sm:text-2.5xl lg:text-3xl mt-6 w-[98%] md:w-10/12 lg:w-7/12 2xl:w-6/12 m-auto text-primary-contrast font-medium md:font-semibold"
        >
          An AI assistant that finds customers in your market, conducts in-depth
          interviews and delivers actionable insights.
        </p>
        <HeroButtons
          onStartInterviewing={navigateAuth}
          onBookDemo={openBookingLinkInNewTab}
        />
      </div>
    </div>
  );
};

export default HeroSection;
